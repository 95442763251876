import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {
    Department,
    getDepartmentList,
    getSearchItem,
    Item,
    makeAdjustment
} from "../../api/drystockAPI";
import {AxiosResponse} from "axios";

interface StocktakeState {
    departmentsError: string | null
    searchError: string | null
    isDepartmentsLoading: boolean
    isSearchLoading: boolean
    departments: Department[]
    items?: Item[]
    lastSearch?: any
    currentPage: number
    pageCount: number

}

let stocktakeInitialState: StocktakeState = {
    departments: [],
    // TODO should be false
    isDepartmentsLoading: true,

    isSearchLoading: false,
    departmentsError: null,
    searchError: null,
    lastSearch: {},
    currentPage: 0,
    pageCount: 0
};

function startLoading(state: StocktakeState) {
    state.isDepartmentsLoading = true
}

function loadingFailed(state: StocktakeState, action: PayloadAction<string>) {
    state.isDepartmentsLoading = false;
    state.departmentsError = action.payload
}

const stocktakeSlice = createSlice({
    name: 'stocktake',
    initialState: stocktakeInitialState,
    reducers: {
        searchStart(state) {
            state.isSearchLoading = true
        },

        setLastSearch(state, {payload}) {
            state.lastSearch = payload;
        },

        searchSuccess(state, {payload}: PayloadAction<{response: AxiosResponse, append: boolean}>) {
            const response = payload.response;

            let data = response.data;
            if (payload.append) {
                state.items = state.items ? state.items.concat(data) : data;
            } else {
                state.items = data;
            }

            let currentPage = parseInt(response.headers['x-pagination-current-page']) ;
            let pageCount = parseInt(response.headers['x-pagination-page-count']);
            if (currentPage && pageCount) {
                state.currentPage = currentPage;
                state.pageCount = pageCount;
            }

            state.isSearchLoading = false;
            state.searchError = null;
        },
        searchFailure(state, {payload}: PayloadAction<string>) {
            state.isSearchLoading = false;
            state.searchError = payload;
        },


        getDepartmentsStart: startLoading,
        getDepartmentsSuccess(state, {payload}: PayloadAction<Department[]>) {
            state.departments = payload;
            state.isDepartmentsLoading = false;
            state.departmentsError = null;
        },

        getDepartmentsFailure: loadingFailed
    }
});
export const {
    searchStart,
    searchSuccess,
    searchFailure,
    getDepartmentsStart,
    getDepartmentsSuccess,
    getDepartmentsFailure,
    setLastSearch,
} = stocktakeSlice.actions, searchItems = (
    siteId: number,
    search: any
): AppThunk => async (dispatch, getState) => {
    // const state = getState();

    try {
        dispatch(searchStart());
        const response = await getSearchItem(siteId, search);
        dispatch(setLastSearch(search));
        dispatch(searchSuccess({response: response, append: false}));
    } catch (err) {
        if (typeof err == "object" && err != null) {
            dispatch(searchFailure(err.toString()))
        } else {
            dispatch(searchFailure('Error'));
        }
    }
}, searchNextPage = (
    siteId: number,
): AppThunk => async (dispatch, getState) => {
    // const state = getState();

    try {
        const stocktake = getState().stocktake;
        const pageCount = stocktake.pageCount;
        const currentPage = stocktake.currentPage;

        if (pageCount > currentPage) {
            dispatch(searchStart());
            // search with last search values but next page
            const response = await getSearchItem(siteId, stocktake.lastSearch, currentPage + 1);

            dispatch(searchSuccess({response: response, append: true}));
        }

    } catch (err) {
        if (typeof err == "object" && err != null) {
            dispatch(searchFailure(err.toString()))
        } else {
            dispatch(searchFailure('Error'));
        }
    }
}, saveStocktake = (itemId: number, siteId: number, data: any): AppThunk => async (dispatch, getState) => {
    const state = getState();

    try {
        dispatch(searchStart());
        await makeAdjustment(itemId, data);

        const response = (await getSearchItem(siteId, state.stocktake.lastSearch));

        dispatch(searchSuccess({response: response, append: false}));
    } catch (err) {
        if (typeof err == "object" && err != null) {
            dispatch(searchFailure(err.toString()))
        } else {
            dispatch(searchFailure('Error'));
        }
    }
}, fetchDepartments = (
    siteId: number
): AppThunk => async (dispatch, getState) => {
    // const state = getState();

    try {
        dispatch(getDepartmentsStart());
        const departments = await getDepartmentList(siteId);
        dispatch(getDepartmentsSuccess(departments));
    } catch (err) {
        if (typeof err == "object" && err != null) {
            dispatch(getDepartmentsFailure(err.toString()))
        } else {
            dispatch(getDepartmentsFailure('Error'));
        }
    }
};


export default stocktakeSlice.reducer;
