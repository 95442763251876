import React from "react";
import {TaxTotal} from "./OrderForm";

interface OTProps {
    total: number;
    taxTotal: TaxTotal[];
    inclusive: boolean;
    taxDescription: string;
    subtotal: number;
}

export const OrderTotal = ({taxTotal, total, inclusive, taxDescription, subtotal}: OTProps) => {
    const totals = taxTotal.map(({total, rate}) => {
        return (
            <tr>
                <td>{`${inclusive ? 'Includes' : 'Total'} ${taxDescription} ${rate}%`}</td>
                <td className="text-right pl-2">{total}</td>
            </tr>
        )
    })

    return (
        <table className="total-table">
            <tbody>
            <tr>
                <td>Subtotal</td>
                <td className="text-right pl-2">{subtotal}</td>
            </tr>
            {totals}
            <tr className="border-top">
                <th>Total</th>
                <th className="text-right pl-2">{total}</th>
            </tr>
            </tbody>
        </table>
    )
}
