import React from "react";
import { Modal } from "react-bootstrap";


interface LOProps {
    show: boolean
}

export const LoaderOverlay = ({show}: LOProps) => {
    if (!show) {
        return null;
    }

    return (
        <div style={{
            top: 0,
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
            display: "block",
            backgroundColor: "black",
            zIndex: 1,
            opacity: 0.25,
        }}/>
    )
}

export const Loader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">

            <div className="spinner-border text-primary"/>

        </div>
    );
}

export const ModalBodyLoader = () => {
    return (
        <Modal.Body>
            <div className="m-5">
                <Loader/>
            </div>
        </Modal.Body>
    );
}


export const PageLoader = ({show}: { show: boolean }) => show ? <div>
    <div>
        <LoaderOverlay show={true}/>

        <div className="m-4 d-flex justify-content-center align-items-center">
            <div className="card" style={{zIndex: 1, width: "auto"}}>
                <div className="card-body">
                    <Loader/>
                </div>
            </div>
        </div>
    </div>
</div> : null;