import {Field, FieldInputProps, FieldRenderProps, Form, FormProps} from "react-final-form";
import React, {ComponentType, FC} from "react";

export const  renderField: FC<FieldRenderProps<any>> = ({id, input, label, disabled, type, meta: {touched, submitError, submitFailed}}) => {
    const className = submitFailed && submitError ? "form-control is-invalid" : "form-control";

    return (
        <div className="form-group">
            <label>{label}</label>
            <input disabled={disabled} className={className} {...input} placeholder={label}/>
            {/*<div className="invalid-feedback">*/}
            {/*    {submitError}*/}
            {/*</div>*/}
        </div>
    )
}

interface StocktakeFormProps {
    onSubmit: (formData: {quantity: number}) => void
    onCancel: () => void
}

export const StocktakeForm = ({onSubmit, onCancel}: StocktakeFormProps) => {

    return (
        <Form
            // mutators={{...arrayMutators}}
            // initialValues={answers}


            onSubmit={onSubmit}

            // decorators={[calculator]}
            subscription={{submitting: true, pristine: true, values: true}}

        >
            {({handleSubmit, pristine, submitting, submitFailed, values, active}) => {
                return (
                    <form onSubmit={handleSubmit}>

                        <Field
                            label={"Quantity"}
                            type="number"
                            /* @ts-ignore */
                            component={renderField}
                            tabIndex={-1}
                            className="form-control"
                            name={"quantity"}
                            readOnly
                        />

                        <div className="clearfix">
                                <button type="submit" className="btn btn-primary float-right">Update</button>
                                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel
                                </button>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}
