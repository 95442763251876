import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Form} from "react-final-form";
import {usePostCreditOrderMutation} from "../services/drystock";

interface CNBProps {
    orderId: number;
}


export const CreditNoteButton = ({orderId}: CNBProps) => {
    const [show, setShow] = useState(false);


    const [
        postCreditOrder,
        postCreditOrderStatus
    ] = usePostCreditOrderMutation();

    useEffect(() => {
        if (postCreditOrderStatus.fulfilledTimeStamp !== undefined && postCreditOrderStatus.data) {
            window.location.href = postCreditOrderStatus.data.redirect;
        }
    }, [postCreditOrderStatus.fulfilledTimeStamp]);


    return (
        <React.Fragment>
            <button className="btn btn-secondary" onClick={() => setShow(true)}
                    disabled={postCreditOrderStatus.isLoading}>New Credit Note
            </button>

            <Modal show={show} onHide={() => setShow(false)} centered>
                {/*@ts-ignore}*/}
                <Modal.Header closeButton>
                    <Modal.Title>Create Credit Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Create a credit note for this purchase order?
                    </p>

                    <p>
                        <small>This will reverse the purchase order</small>
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    {/* TODO this component should handle the credit note request and close on success */}

                    <button type="button" className="btn btn-secondary" disabled={postCreditOrderStatus.isLoading}
                            onClick={() => setShow(false)}>
                        Cancel
                    </button>

                    <Form
                        onSubmit={() => postCreditOrder(orderId)}
                        render={({handleSubmit, submitting, pristine, invalid, values, errors}) => (

                            <form onSubmit={handleSubmit}>
                                <button type="submit" className="btn btn-primary">OK</button>
                            </form>
                        )}
                    />
                </Modal.Footer>
            </Modal>

        </React.Fragment>

    );
}
