import React from "react";
import {PurchaseOrder} from "./OrderForm";

import {Form} from "react-final-form";
import {DateTimeFormComponent} from "../common/DateTimeFormComponent";

interface ODProps {
    submitting: boolean
    onSubmit: (values: PurchaseOrder) => any
    order: Pick<PurchaseOrder,  'supplierorderid' | 'orderOrderDate' | 'orderRequiredDate' | 'orderReceivedDate'>
}

export const OrderDates = ({order, onSubmit,submitting}: ODProps) => (
    <div className="d-flex flex-wrap mr-n1">
        <Form initialValues={order} onSubmit={(values) => {
            // @ts-ignore
            onSubmit(values);
        }}>
            {({handleSubmit, values, valid, dirty}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        {/* search box */}
                        <div className="d-flex flex-row flex-wrap">
                            {/*<label htmlFor="search-input">Search</label>*/}


                            {order.orderRequiredDate ?
                                <DateTimeFormComponent displayName="Order Required Date" submitButton dateFormat={'DD MMM YYYY'}
                                                       name='orderRequiredDate'/>
                                : null}
                            {order.orderOrderDate ?
                                <DateTimeFormComponent displayName="Order Date" submitButton dateFormat={'DD MMM YYYY'} name='orderOrderDate'/>
                                : null}
                            {order.orderReceivedDate ?
                                <DateTimeFormComponent displayName="Order Received Date" submitButton dateFormat={'DD MMM YYYY'}
                                                       name='orderReceivedDate'/>
                                : null}
                        </div>
                    </form>
                )
            }}
        </Form>

        {/*<div>{itemOrder.taxtypeid}</div>*/}
    </div>
);