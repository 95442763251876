import React, {useState} from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Moment from "moment";

interface DateTimeSelectProps {
    onSubmit: (value?: string | Moment.Moment) => void
}

export const DateTimeSelect = ({onSubmit}
                                   : DateTimeSelectProps
) => {
    const [dateTime, setDateTime] = useState<Moment.Moment | string>();

    const isValid = Moment.isMoment(dateTime);

    return (
        <div>
            {/*@ts-ignore*/}
            <DateTime
                onChange={setDateTime}
                input={false}
            />
            <div className="clearfix">
                <div className="float-right">
                    <button onClick={() => onSubmit(dateTime)} disabled={!isValid}
                            className="btn btn-primary float-right">Next
                    </button>
                </div>
            </div>
        </div>
    )
};
