import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import {Supplier, Tank} from "./OrderWetstock";
import DateTime from "react-datetime";
import {ModalBody, ModalFooter} from "react-bootstrap";
import {usePostNewDropMutation} from "../services/drystock";


interface Drop {
    itemOrderId?: number
}

//TODO bootstrap / yii error components of this should be turned into reusable components
interface WFBaseProps {
    onCancel?: () => any;

    id: string;
    asModalContent?: boolean;
    title?: string;
    closeOnFinish?: boolean;
}

interface WFProps extends WFBaseProps {
    tanks?: Tank[]
    tankId?: number
    dateTime?: string
    volume?: number
    suppliers?: Supplier[]
    initialValues: any
    submitting?: boolean
    onSubmit: (dropData: any) => void
    unitCost?: number
    submitText?: string
}

interface WFNewProps extends WFBaseProps {
    tankId: number
    suppliers: Supplier[]
}

// TODO combine
export const WetstockFormNewOrder = ({tankId, closeOnFinish, ...rest}: WFNewProps) => {
    const [postDrop, postDropStatus] = usePostNewDropMutation();

    const onCancel = rest.onCancel;

    useEffect(() => {
        if (postDropStatus.fulfilledTimeStamp !== undefined) {
            closeOnFinish && onCancel && onCancel();
        }
    }, [onCancel, postDropStatus, closeOnFinish]);

    return <WetstockForm submitting={postDropStatus.isLoading} tankId={tankId} onSubmit={(dropData: any) => {
        postDrop(dropData);
    }} {...rest} initialValues={{
        storageTankId: tankId
    }}/>
}

export const WetstockForm = ({
                                 submitText = 'Submit',
                                 initialValues,
                                 onCancel,
                                 dateTime,
                                 unitCost,
                                 volume,
                                 tankId,
                                 tanks,
                                 onSubmit,
                                 submitting,
                                 suppliers,
                                 id,
                                 title,
                                 asModalContent
                             }: WFProps) => {
    let errors: { [key: string]: string } = {};


    if (unitCost !== undefined) {
        initialValues.unitCost = unitCost;
    }

    if (volume !== undefined) {
        initialValues.volume = volume;
    }

    if (dateTime !== undefined) {
        initialValues.dateTime = dateTime;
    }


    let tankInput: React.ReactElement | null = null;


    if (tankId !== undefined) {
        initialValues.storageTankId = tankId;

        tankInput = <Field
            type="hidden"
            id="storageTankIdInput"
            component="input"
            className="form-control"
            name="storageTankId"
        />
    } else if (tanks !== undefined) {
        if (tanks.length > 0) {
            initialValues.storageTankId = tanks[0].storagetankid;


        }
        tankInput =
            <div className="form-group">
                <label>Tank</label>
                <Field
                    className="form-control"
                    name="storageTankId"
                    component="select">
                    {tanks.map((tank) => (<option value={tank.storagetankid}>
                        {tank.name}
                    </option>))}
                </Field>
            </div>


    }


    return (

        <Form
            validate={() => {
                return errors
            }}
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({handleSubmit, pristine, invalid, values}) => {

                let header = title ? <h4>{title}</h4> : null

                const form = <form id={id} onSubmit={handleSubmit}>
                    {header}

                    {dateTime === undefined ?
                        <Field name="dateTime">
                            {({input, meta}) => (

                                <div className="form-group">
                                    <label>Date</label>

                                    <DateTime
                                        onChange={(dateTime) => {
                                            if (!(typeof dateTime === 'string')) {
                                                input.onChange(dateTime.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                        }}
                                        input={false}
                                    />
                                    <small id="dateTimeHelp" className="form-text text-muted">Select the date and time
                                        the fuel drop
                                        occurred.</small>
                                    {/* TODO bit of a hack to get invalid-feedback to show - could also set element style to display: block*/}

                                    {meta.submitError && <div className="is-invalid"/>}
                                    <div className="invalid-feedback">
                                        {meta.submitError}
                                    </div>
                                </div>
                            )}
                        </Field> : <Field
                            type="hidden"
                            id="dateTime"
                            component="input"
                            className="form-control"
                            name="dateTime"
                        />}


                    {/*       TODO create render component */}


                    {tankInput}


                    {volume === undefined ?
                        <Field id="volumeInput" name="volume">
                            {({input, meta}) => (

                                <div className="form-group">
                                    <label htmlFor={input.id}>Volume</label>

                                    <input
                                        {...input}
                                        type="number"
                                        className={meta.submitError ? "form-control is-invalid" : "form-control"}
                                    />

                                    <div className="invalid-feedback">
                                        {meta.submitError}
                                    </div>
                                </div>
                            )}
                        </Field> : <Field
                            type="hidden"
                            id="volumeInput"
                            component="input"
                            className="form-control"
                            name="volume"
                        />}

                    {unitCost === undefined ?
                        <Field id="unitCostInput" name="unitCost">
                            {({input, meta}) => (

                                <div className="form-group">
                                    <label htmlFor={input.id}>Unit Cost</label>

                                    <input
                                        {...input}
                                        type="number"
                                        className={meta.submitError ? "form-control is-invalid" : "form-control"}
                                    />

                                    <div className="invalid-feedback">
                                        {meta.submitError}
                                    </div>
                                </div>
                            )}
                        </Field> : <Field
                            type="hidden"
                            id="unitCostInput"
                            component="input"
                            className="form-control"
                            name="unitCost"
                        />}

                    {suppliers ?
                        <Field id="supplierIdInput" name="supplierId">
                            {({input, meta}) => (

                                <div className="form-group">
                                    <label htmlFor={input.id}>Fuel Supplier</label>

                                    <select {...input}
                                            className={meta.submitError ? "form-control is-invalid" : "form-control"}>
                                        <option>
                                            {/* TODO shouldn't really have a non answer default*/}
                                            Select a supplier
                                        </option>
                                        {suppliers.map((supplier) => {
                                            return (
                                                <option value={supplier.contactid}>
                                                    {supplier.organisationname}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    <div className="invalid-feedback">
                                        {meta.submitError}
                                    </div>
                                </div>
                            )}
                        </Field>
                        : null}

                    {!asModalContent ?

                        <div className="d-flex">

                            {
                                onCancel ?
                                    <button onClick={onCancel} type="button" className="btn btn-secondary">
                                        Cancel
                                    </button>
                                    : null
                            }

                            <button type="submit" className="btn btn-primary ml-auto" onClick={handleSubmit}
                                    disabled={submitting}>
                                {submitText}
                            </button>
                        </div>
                        : null
                    }
                </form>


                if (asModalContent) {
                    return (
                        <React.Fragment>
                            <ModalBody>
                                {form}
                            </ModalBody>
                            <ModalFooter>
                                {onCancel ?
                                    <button onClick={onCancel} type="button" className="btn btn-secondary">
                                        Cancel
                                    </button>
                                    : null
                                }

                                <button onClick={handleSubmit} type="submit" className="btn btn-primary"
                                        disabled={submitting}>
                                    {submitText}
                                </button>
                            </ModalFooter>
                        </React.Fragment>
                    )
                } else {
                    return form;
                }
            }}
        />
    );
}
