import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import {Item} from "../../api/drystockAPI";
import {useGetItemQuery, useUpdateItemMutation} from "../../services/drystock";
import {Loader} from "../Loader";

interface PriceFormProps {
    onSubmit: (item: Item) => void
    formData: Partial<Item>
    onCancel: () => void
    submitting: boolean
}

const PriceForm = ({formData, onCancel, onSubmit, submitting}: PriceFormProps) => {

    return (
        <Form
            onSubmit={onSubmit}
            subscription={{submitting: true, pristine: true, values: true}}
            initialValues={formData}
        >
            {({handleSubmit, pristine, submitFailed, values, active}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="price-form-unitprice">Price</label>

                            <Field id='price-form-unitprice' className="form-control" component='input'
                                   type='number'
                                   name='unitprice'/>
                        </div>


                        <div className="d-flex">
                            <button type="button" disabled={submitting} className="btn btn-secondary"
                                    onClick={onCancel}>No
                            </button>
                            <button type="submit" disabled={submitting} className="btn btn-primary ml-auto">Yes
                            </button>
                        </div>
                    </form>
                )
            }}
        </Form>
    );
}

interface ReceiveStockPriceAdjustProps {
    onClose: () => void
    itemId: number
    closeOnFinish?: boolean
}

export const ReceiveStockPriceAdjust = ({onClose, closeOnFinish, itemId}: ReceiveStockPriceAdjustProps) => {
    const [innerItemId, setInnerItemId] = React.useState<number>();

    let currentItemId = itemId;

    if (innerItemId !== undefined) {
        currentItemId = innerItemId;
    }
// TODO nested margin updates - perhaps should change margin data return in backend
    const [updateItem, updateItemStatus] = useUpdateItemMutation();

    useEffect(() => {
        if (updateItemStatus.fulfilledTimeStamp !== undefined) {
            closeOnFinish && onClose();
        }
    }, [updateItemStatus.fulfilledTimeStamp, onClose, closeOnFinish])

    const {
        data, error, isLoading
    } = useGetItemQuery({id: '' + currentItemId});


    if (isLoading) {
        return <div className="m-5">
            <Loader/>
        </div>
    }
    if (error || !data) {
        return <div>rejected</div>
    }

    const item = data;


    const targetUnitPrice = item.marginData.target;
    const text = item.marginData.text;


    return (

        <div>
            <p>
                The current price is {item.unitprice}.<br/>

                {text}<br/>

                Do you want to change the unit price?<br/>
                {/*TODO check existence of margin data */}

            </p>

            <PriceForm
                submitting={updateItemStatus.isLoading}
                onCancel={onClose}
                onSubmit={(values) => {
                    updateItem(values);
                }}
                formData={{unitprice: targetUnitPrice, itemid: item.itemid}}
            />
        </div>
    )
}
