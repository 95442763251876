import React from 'react';
import ReactDOM from 'react-dom/client';
import {Stocktake} from "./Stocktake";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

export default () => {
    const container = document.getElementById('stocktake');

    if (container) {
        const siteId = container.getAttribute('data-site-id');

        if (siteId !== null) {
            const router = createBrowserRouter([{
                path: "*",
                element: <Stocktake siteId={parseInt(siteId)}/>
            }])

            const root = ReactDOM.createRoot(
                container
            )

            root.render(
                <RouterProvider router={router}/>
            )
        }
    }
};
