import {Field, useFormState} from "react-final-form";
import DateTime from "react-datetime";
import React from "react";

import {Save} from "react-feather";

interface DTFCProps {
    dateFormat: string
    name: string
    submitButton?: boolean
    displayName: string
}

export const DateTimeFormComponent = ({name, dateFormat, submitButton, displayName}: DTFCProps) => {
    const {dirty, valid} = useFormState();


    return (
        <Field
            name={name}

            validate={(value) => {

                return undefined;
                if ((typeof value === 'string')) {
                    return 'Must be a valid date'
                }

                return undefined;
            }}
        >

            {props => {
                return (
                    <div className="mr-2 flex-grow-1">
                        <DateTime
                            dateFormat={dateFormat}
                            timeFormat={false}
                            // input={false}
                            // onChange={(dateTime) => this.setState({dateTime: dateTime})}

                            // name={props.input.name}
                            value={props.input.value}
                            onChange={(value) => {
                                let changeValue = value;
                                if (!(typeof value === 'string')) {
                                    changeValue = value.format(dateFormat)
                                }

                                props.input.onChange(changeValue)
                            }}

                            // @ts-ignore
                            renderInput={(props, openCalendar, closeCalendar) => {
                                function clear() {
                                    props.onChange({target: {value: ''}});
                                }

                                return (
                                    <div className="form-group">
                                        <label className="control-label" htmlFor={props.id}>{displayName}</label>

                                        {/*<input {...props} />*/}
                                        {/*<input {...props} />*/}
                                        {/*<button onClick={openCalendar}>open calendar</button>*/}
                                        {/*<button onClick={closeCalendar}>close calendar</button>*/}
                                        {/*<button onClick={clear}>clear</button>*/}

                                        <div className="input-group mb-3">


                                            <input {...props} type="text" className="form-control"
                                            />
                                            {submitButton
                                                ? <div className="input-group-append">
                                                    <button type="submit" className="btn btn-primary"
                                                            disabled={!(valid && dirty)}>

                                                        <Save size={20}/>
                                                    </button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </div>
                );
            }}
        </Field>
    );
}
