import ItemOrderForm from "./ItemOrderForm";
import {TaxInclusiveForm} from "./TaxInclusiveForm";
import {OrderDates} from "./OrderDates";
import {ItemOrderTable} from "./ItemOrderTable";
import {OrderTotal} from "./OrderTotal";
import {ReferenceForm} from "./ReferenceForm";
import {CreditNoteButton} from "./CreditNoteButton";
import {DuplicateButton} from "../features/order/DuplicateButton";
import React, {JSX, useEffect, useState} from "react";
import {StockReceiveWidget} from "./StockReceiveWidget";
import {ErrorPage} from "../common/ErrorPage";
import {Item} from "../api/drystockAPI";
import {Items} from "./Items";
import {Tank} from "../order-wetstock/OrderWetstock";
import {Modal} from "react-bootstrap";
import {
    useDeleteOrderMutation,
    useGetOrderQuery,
    usePostDuplicateOrderMutation,
    usePostItemOrderMutation,
    usePostPlaceOrderMutation,
    usePostReceiveOrderMutation,
    useUpdateOrderMutation
} from "../services/drystock";
import {LoaderOverlay, PageLoader} from "../common/Loader";
import {ReceiveAllFormModal} from "../common/stock-receive/ReceiveAllForm";

export interface TaxTotal {
    rate: string,
    total: number
}

export interface TaxRate {
    description: string
    taxrateid: number
}

export interface PurchaseOrder {
    cancelText: string;
    submitText: string;
    receiveText: {
        one: string
        all: string
    }
    placeText: string
    deleteText: string
    canDuplicate: boolean
    supplierorderid: number
    emailLink?: string
    pdfLink?: string
    orderdate: string
    requireddate: string
    stateDescription: string
    documentnumber: string
    organisationName: string
    orderRequiredDate?: string
    orderOrderDate?: string
    orderReceivedDate?: string
    invoicetaxtype: string
    canPlace: boolean
    canReceive: boolean
    canCredit: boolean
    canSupply: boolean
    cancellable: boolean
    editable: boolean
    isActive: boolean
    taxDescription: string
    taxTotal: number
    subtotal: number
    total: number
    contactid: number
    itemorders: ItemOrder[]
    taxTotalsByRate: TaxTotal[]
    validTaxRates: TaxRate[]
    downloadPDFText: string
    finaliseText: string
}

export interface ItemOrder {
    //  TODO should probably just load the full order in cases where itemorders are being rendered
    submitText: string;
    cancelText: string;
    unitCostText: string;
    quantityText: string;
    taxRateText: string;
    totalText: string;
    suppliedQuantityText: string;
    orderDate: string
    organisationName: string
    suppliedQuantity: number
    unsuppliedQuantity: number
    taxDescription?: string
    itemName: string
    shouldAdjustPrice: boolean
    unitcost: number
    total: number
    itemorderid: number
    tanks: Tank[]
    orderqty: number
    taxRateId: number
    supplierorderid: number
    itemid: number
    supplyCosts: { quantity: number, cost: number }[]
}

interface OFProps {
    orderId: number
    siteId: number
}

interface AddItemProps extends OFProps {
    supplierId: number
}

const AddItem = ({siteId, orderId, supplierId}: AddItemProps) => {
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [show, setShow] = useState<number>();

    const [
        postItemOrder,
        postItemOrderStatus
    ] = usePostItemOrderMutation();


    let modalTitle: string;
    let modalContent: JSX.Element;

    if (selectedItem) {
        // @ts-ignore
        modalTitle = selectedItem.description;
        modalContent =
            <ItemOrderForm
                formData={{
                    // @ts-ignore
                    unitcost: selectedItem.costprice,
                    itemid: selectedItem.itemid,
                    supplierorderid: orderId,
                }}
                onSubmit={({formData}: { formData: ItemOrder }) => {
                    postItemOrder(formData);
                }}
                onCancel={() => setShow(undefined)}
            />
    } else {
        modalTitle = 'Select Item';
        modalContent = <Items
            supplierId={supplierId}
            siteId={siteId}
            onSelect={(item: Item) => {
                setSelectedItem(item);
            }}/>
    }

    return (
        <React.Fragment>
            <div>
                <button className="btn btn-secondary" onClick={() => {
                    setSelectedItem(null);
                    setShow(Date.now());
                }}>Add Item
                </button>
            </div>

            <Modal
                show={!!(show && (!postItemOrderStatus.fulfilledTimeStamp || show > postItemOrderStatus.fulfilledTimeStamp))}
                onHide={() => setShow(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent}

                </Modal.Body>
            </Modal>
        </React.Fragment>

    )
}


export const OrderForm = (props: OFProps) => {


    const [
        deleteOrder,
        deleteOrderStatus
    ] = useDeleteOrderMutation();


    useEffect(() => {
        if (deleteOrderStatus.data !== undefined) {
            window.location.href = deleteOrderStatus.data.redirect;
        }
    }, [deleteOrderStatus.data]);

    const {
        data,
        error,
        isLoading,
        isError,
        isFetching
    } = useGetOrderQuery({id: props.orderId});

    const [
        postPlaceOrder,
        postPlaceOrderStatus
    ] = usePostPlaceOrderMutation();

    const [
        updateOrder,
        updateOrderStatus
    ] = useUpdateOrderMutation();

    const [
        postReceiveOrder,
        postReceiveOrderStatus
    ] = usePostReceiveOrderMutation();

    const [
        postDuplicateOrder,
        postDuplicateOrderStatus
    ] = usePostDuplicateOrderMutation();

    useEffect(() => {
        if (postDuplicateOrderStatus.data) {
            window.location.href = postDuplicateOrderStatus.data.redirect;
        }
    }, [postDuplicateOrderStatus.data]);

    const {
        orderId,
        siteId
    } = props;

    const [selectedItemOrderId, setSelectedItemOrderId] = useState<number | null>(null);
    const [receiveAll, setReceiveAll] = useState<boolean>(false);


    if (isLoading || postDuplicateOrderStatus.isLoading || postDuplicateOrderStatus.data) {
        return <PageLoader show={true}/>
    }

    if (isError || !data) {
        return <ErrorPage show={true}/>
    }

    const supplierId = data.contactid;


    let stockReceiveItemOrderWidget = null;
    if (selectedItemOrderId != null) {
        const itemOrder = data.itemorders.find(({itemorderid}) => selectedItemOrderId === itemorderid);

        if (itemOrder) {
            stockReceiveItemOrderWidget = <StockReceiveWidget
                closeOnFinish
                itemOrder={itemOrder}
                onClose={() => {
                    setSelectedItemOrderId(null)
                }}
            />

        }
    }

    const {editable, canPlace, canReceive, canCredit, itemorders, canSupply, cancellable} = data;

    const buttons = [];

    if (data.pdfLink) {
        buttons.push(<a key="emailButton" className={"btn btn-primary"} href={data.emailLink}>Email Supplier</a>);

        buttons.push(<a key="downloadPdfButton" className={"btn btn-primary"} href={data.pdfLink} target="_blank"
                        rel="noreferrer">{data.downloadPDFText}</a>);
    }

    if (canPlace) {
        buttons.push(<button key="placeOrderButton" className="btn btn-secondary"
                             disabled={postPlaceOrderStatus.isLoading} onClick={() => {
            postPlaceOrder(orderId)
        }}>{data.placeText}</button>);
    }

    if (canReceive) {
        buttons.push(<button key="finaliseOrderButton" className="btn btn-secondary"
                             disabled={postReceiveOrderStatus.isLoading} onClick={() => {
            postReceiveOrder(orderId);
        }}>{data.finaliseText}</button>);
    }

    if (canCredit) {
        buttons.push(<CreditNoteButton key="creditNoteButton" orderId={orderId}/>);
    }

    if (canSupply) {
        buttons.push(<button className="btn btn-primary" key="receiveAllButton"
                             onClick={() => {
                                 if (data.itemorders.length > 0) {
                                     setReceiveAll(true);
                                 }
                             }}>{data.receiveText.all}</button>);
    }

    let cancelButton = null;
    //
    if (editable) {
        cancelButton = <button key="cancelButton" disabled={deleteOrderStatus.isLoading} onClick={() => {
            deleteOrder(orderId);
        }} className="btn btn-danger">{data.deleteText}</button>;
    }
    // else if (cancellable) {
    //     cancelButton = <button onClick={() => {
    //         dispatch(cancelOrder(orderId))
    //     }} className="btn btn-secondary">Cancel</button>;
    // }


    const {
        supplierorderid,
        orderOrderDate,
        orderReceivedDate,
        orderRequiredDate,
        documentnumber,
        invoicetaxtype
    } = data;

    return (
        <div>
            <LoaderOverlay show={isFetching || updateOrderStatus.isLoading}/>

            <div className=" d-flex justify-content-between flex-row">
                <h4>
                    {data.stateDescription}
                </h4>

                <div>
                    {data.canDuplicate ? <DuplicateButton orderId={orderId}
                                                          postDuplicateOrder={() => postDuplicateOrder(orderId)}/> : null}
                    {cancelButton ? <div className="ml-1">{cancelButton}</div> : null}
                </div>
            </div>

            <div className="">
                <h4>
                    {data.organisationName}
                </h4>

                <div className="d-flex flex-wrap mr-n1">
                    <div className="flex-grow-1 mr-2" style={{maxWidth: 330}}>
                        <ReferenceForm
                            submitting={updateOrderStatus.isLoading}
                            onSubmit={updateOrder}
                            supplierOrder={{supplierorderid, documentnumber}}
                        />
                    </div>

                    <div className="flex-grow-1 mr-2" style={{maxWidth: 330}}>
                        <TaxInclusiveForm
                            submitting={updateOrderStatus.isLoading}
                            onSubmit={updateOrder}
                            supplierOrder={{supplierorderid, invoicetaxtype}}
                        />
                    </div>
                </div>

                <OrderDates
                    submitting={updateOrderStatus.isLoading}
                    order={{supplierorderid, orderOrderDate, orderReceivedDate, orderRequiredDate}}

                    onSubmit={updateOrder}
                />
            </div>

            <ItemOrderTable
                order={data}

                onReceive={setSelectedItemOrderId}
            />

            {/*<ItemSearchInput siteId={siteId} supplierId={supplierId}/>*/}

            <div className="border-top">


                <div className="d-flex">
                    {
                        editable
                            ? <AddItem orderId={orderId} siteId={siteId} supplierId={supplierId}/>
                            : null
                    }
                    <div className="ml-auto">
                        <OrderTotal
                            subtotal={data.subtotal}
                            taxDescription={data.taxDescription}
                            inclusive={data.invoicetaxtype === "TAXDISPLAY_INCLUSIVE"}
                            taxTotal={data.taxTotalsByRate}
                            total={data.total}
                        />
                    </div>
                </div>
            </div>

            {
                buttons.length !== 0
                    ? <div className=" d-flex justify-content-end">
                        {buttons.map(button => <span style={{marginLeft: 5}}>{button}</span>)}
                    </div>
                    : null
            }

            <ReceiveAllFormModal title={data.receiveText.all} order={data} onClose={() => setReceiveAll(false)} show={receiveAll} />

            {stockReceiveItemOrderWidget}
        </div>
    );
}
