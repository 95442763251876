import React, {PureComponent} from 'react';

export default class NoResults extends PureComponent {
    render() {
        return (
            <div className="text-center" style={{padding: 15}}>
                <em>No Results</em>
            </div>
        );
    }
}
