import React, {useState} from 'react';
import ItemSearchResults from "../common/ItemSearchResults";
import NoResults from "../common/NoResults";
import {Item} from "../api/drystockAPI";
import {ItemSearch, ItemSearchChange} from "../common/ItemSearch";
import {useGetItemsQuery} from "../services/drystock";
import {Loader} from "../common/Loader";
import {ItemReceive} from "../common/stock-receive/ItemReceive";


interface ReceiveStockProps {
    siteId: number;

}

export const ReceiveStock = ({siteId}: ReceiveStockProps) => {
    const [selectedItem, setSelectedItem] = useState<Item>();
    const [search, setSearch] = useState<ItemSearchChange>();

    let searchTerm = search?.searchTerm ?? '';


    const {data, error, isLoading, refetch}
        = useGetItemsQuery({siteId, search: searchTerm,outstandingOrders: true, expand: ['outstandingOrders']});

    let modalContent = null;

    if (selectedItem) {
        modalContent = <ItemReceive
            onReceived={() => refetch()}
            show={true}
            item={selectedItem}
            itemName={selectedItem.description}
            closeModal={() => setSelectedItem(undefined)}
        />
    }

    if (error) {
        return null;
    }

    if (isLoading) {
        return <Loader/>
    }

    if (!data || data.length === 0) {
        return <NoResults/>
    }

    let itemsContent = data.map((item) => {
        return (
            <div className="action-card" key={item.itemid}>
                <a onClick={() => {
                    setSelectedItem(item);
                }} href="javascript:void(0)">
                    <div>{item.description}</div>
                    {/*<div>{item.stockonhand ? item.stockonhand : 0}</div>*/}
                </a>
            </div>
        );
    });

    // TODO search / adjustment error handling
    return (
        <div>
            <ItemSearch onSearch={setSearch}/>
            <ItemSearchResults search={searchTerm} isBarcode={search?.isBarcode ?? false}/>
            {modalContent}

            <div className="search-results-dropdown">
                <div className="action-card-container">
                    {itemsContent}
                </div>
            </div>
        </div>
    );
}
