import NoResults from "../common/NoResults";
import ItemSearchResults from "../common/ItemSearchResults";
import React, {useState} from "react";
import {Item} from "../api/drystockAPI";
import {ItemSearch, ItemSearchChange} from "../common/ItemSearch";
import {useGetItemsQuery} from "../services/drystock";
import {Loader} from "../common/Loader";

// TODO move to API


interface ItemsProps {
    siteId: number;

    onSelect(item: Item): void;

    supplierId?: number
}

export const Items = ({onSelect, siteId, supplierId}: ItemsProps) => {
    const [
        search,
        setSearch
    ] = useState<ItemSearchChange>();

    const searchTerm = search?.searchTerm ?? '';
    const isBarcode = search?.isBarcode ?? false;


    const {
        data,
        error,
        isLoading,
        isFetching
    }
        = useGetItemsQuery({
        siteId,
        search: searchTerm,
        outstandingOrders: false,
        supplierId: supplierId,
        wetstock: true,
        overheads: true
    });


    if (isLoading) {
        return <Loader/>
    }

    if (error || !data) {
        //TODO error handling
        return <div>Error</div>
    }

    // TODO cases: 1
    // TODO pending


    let innerContent;
    if (!data || data.length === 0) {
        innerContent = <div className="action-card"><NoResults/></div>
    } else {
        innerContent = data.map((item) => {
            return (
                <div className="action-card" key={item.itemid}>
                    <a href="javascript:void(0)" onClick={() => {
                        onSelect(item);
                    }}>
                        <div>{item.description}</div>
                    </a>
                </div>
            );
        });
    }

    let content =
        <div className="search-results-dropdown">
            <div className="action-card-container">
                {innerContent}
            </div>
        </div>;

    if (isFetching) {
        content = (
            <div className="loading" style={{width: '100%'}}>
                <div className="loading-overlay" style={{width: '100%'}}>
                    {content}
                </div>
            </div>
        )
    }

    let searchResults = null;

    if (isFetching) {
        searchResults = null;
    } else {
        searchResults = (
            // TODO search is wrong for this should only show the search that is currently being shown
            <ItemSearchResults search={searchTerm} isBarcode={isBarcode}/>
        )
    }


    return (
        <div>
            <ItemSearch onSearch={setSearch}/>
            {searchResults}
            {content}
        </div>
    );
}






