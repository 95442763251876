function barcodeScanning(onScan) {
    let scanning = false, barcode = '';
    const keys = {};

    const onKeyDown = (e) => {
        // this function looks for barcodes entered by a keyboard wedge barcode scanner and adds them if they exist
        keys[e.key] = true;

        if (scanning) {
            if (keys.Control) {
                if (keys.c || keys.C) {
                    onScan(barcode);

                    barcode = "";
                }
            } else {
                e.preventDefault();
                barcode = (barcode || "") + e.key;
            }
        }
        if (keys.Control && (keys.b || keys.B)) {
            e.preventDefault();
            scanning = true;

            setTimeout(() => {
                scanning = false;
                barcode = "";
            }, 250);
        }
    };

    const onKeyUp = (e) => {
        keys[e.key] = false;
    };

    // TODO Alpha keys in barcode include the string `Shift`, detach
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return this;
}

export default barcodeScanning;
