import React, {PropsWithChildren, useEffect, useState} from "react";
import {Field} from "react-final-form";
import diff from 'object-diff'

const FieldPrefixContext = React.createContext("");

interface FPProps {
    prefix: string
}

export const FieldPrefix: React.FunctionComponent<PropsWithChildren<FPProps>> = ({prefix, children}) => (
    <FieldPrefixContext.Provider value={prefix}>
        {children}
    </FieldPrefixContext.Provider>
);

interface PFProps {
    name: string
}

export const PrefixedField: React.FunctionComponent<PropsWithChildren<PFProps>> = ({name, ...props}) => (
    <FieldPrefixContext.Consumer>
        {prefix => <Field name={prefix.length === 0 ? name : `${prefix}.${name}`} {...props} />}
    </FieldPrefixContext.Consumer>
);

interface BaseProps {
    delay: number
}

interface InjectedProps {
    values: object,
    submitting: boolean,
    submit:  () => any | undefined
    toCheck?: string[]
}

let timeout: undefined | NodeJS.Timeout;

export const AutoSave = ({values, submitting, delay, submit}: InjectedProps & BaseProps) => {
    const [oldValues, setValues] = useState(values);

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            const difference = diff(oldValues, values);

            let changed = Object.keys(difference);

            // also check that the length of oldValues and values are the same as diff only checks difference between properties that exist
            const hasChanged = changed.length > 0 || Object.keys(oldValues).length !== Object.keys(values).length;



            console.log(changed);
            // @ts-ignore

            if (changed.map((value) =>   oldValues[value] != values[value]).reduce((prev, curr) => prev || curr, false) && !submitting) {
                // values have changed and not currently submitting
                setValues(values);
                console.log('Autosaving');
                submit();
            }
        }, delay)
    }, [values]);

    return null;
};