import {ItemOrderRow} from "./ItemOrderRow";
import {PurchaseOrder} from "./OrderForm";
import React from "react";

interface IOTProps {
    order: PurchaseOrder
    onReceive: (itemOrderId: number) => void
}

export const ItemOrderTable = ({
                                   onReceive,
                                   order
                               }: IOTProps) => {
    return (

        <React.Fragment>
            {order.itemorders.map((itemOrder) => {
                return (
                    <ItemOrderRow
                        order={order}
                        itemOrder={itemOrder}
                        onReceive={onReceive}
                    />
                );
            })}
        </React.Fragment>
    );
};