// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {ItemOrder} from "../orders/OrderForm";
import {Item} from "../api/stocktakeAPI";
import {addCsrf} from "./fetch-options";

// Define a service using a base URL and expected endpoints
export const itemApi = createApi({
    reducerPath: 'itemApi',
    tagTypes: ['Item'],
    baseQuery: fetchBaseQuery(addCsrf({
        baseUrl: '/itemdrystock/'
    })),
    endpoints: (builder) => ({
        getOutstandingItemOrders: builder.query<ItemOrder[], { itemId: string }>({
            query: (arg) => `itemdrystock/outstanding-orders/${arg.itemId}`,
        }),
        getItem: builder.query<Item, { id: string }>({
            query: ({id}) => `itemdrystock/view/${id}`,

            providesTags: (result) => {
                if (result) {
                    const id = result.itemid;
                    return [{type: 'Item', id}];
                }
                return [];
            }
        }),
        updateItem: builder.mutation<Item, Partial<Item>>({
            query: (body) => ({
                url: `itemdrystock/ajax-update/${body.itemid}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: 'Item', id: arg.itemid}],
        }),
    }),
})

export const {useGetOutstandingItemOrdersQuery, useGetItemQuery, useUpdateItemMutation} = itemApi