import { combineReducers } from '@reduxjs/toolkit'


import stocktakeReducer from "../features/stocktake/stocktakeSlice";
import {drystockApi} from "../services/drystock";
import {itemApi} from "../services/item";
import {reconciliationApi} from "../services/reconciliation";

const rootReducer = combineReducers({
    stocktake: stocktakeReducer,
    drystockApi: drystockApi.reducer,
    reconciliationApi: reconciliationApi.reducer,
    itemApi: itemApi.reducer,
});


export default rootReducer
