import React from 'react';
import {OrderForm} from "./OrderForm";
import ReactDOM from "react-dom/client";
import store from "../app/store";
import {Provider} from "react-redux";

export default () => {

    const container = document.getElementById('supplier-orders');

    if (container) {
        const siteId = container.getAttribute('data-site-id');
        const orderId = container.getAttribute('data-order-id');

        if (siteId && orderId) {
            const root = ReactDOM.createRoot(
                container
            )

            root.render(
                <React.StrictMode>
                    <Provider store={store}>
                        <OrderForm siteId={+siteId} orderId={+orderId}/>
                    </Provider>
                </React.StrictMode>
            );
        }
    }
};
