import {Provider} from "react-redux";
import store from "../../app/store";
import React from "react";


// TODO combine with credit note button ?
// TODO loading state
interface DBProps {
    orderId: number;
    postDuplicateOrder: () => void;
}


export const DuplicateButton = ({postDuplicateOrder}: DBProps) => {

    return (

        <Provider store={store}>
            <button className="btn btn-secondary"
                    onClick={() => postDuplicateOrder()}>Duplicate
            </button>
        </Provider>
    );
}

