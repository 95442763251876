import {FetchBaseQueryArgs} from "@reduxjs/toolkit/dist/query/react";
import getCsrfToken from "../common/csrfToken";


export const addCsrf = (fetchOptions: FetchBaseQueryArgs) => {
    const csrf = getCsrfToken();

    if (csrf) {
        fetchOptions.headers = [
            ['X-CSRF-Token', csrf]
        ];
    }

    return fetchOptions;
}