import {Field, Form} from "react-final-form";
import {AutoSave} from "../common/FinalFormUtils";
import {TIFProps} from "./TaxInclusiveForm";
import React from "react";

export const ReferenceForm = ({supplierOrder, onSubmit, submitting}: TIFProps) => {
    return (
        <Form
            initialValues={supplierOrder}
            onSubmit={onSubmit}
            // subscription={{submitting: true, pristine: true, values: true}}
        >
            {({handleSubmit, submitFailed, values, active}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <AutoSave
                            delay={1500}
                            values={values}
                            submitting={submitting}
                            submit={handleSubmit}
                        />

                        <div className="form-group">
                            <label htmlFor="orderReference">Reference</label>
                            <Field
                                className="form-control"
                                name={`documentnumber`}
                                component="input"
                                type="text"
                                autoComplete="off"
                                id="orderReference"
                            />
                        </div>

                    </form>
                )
            }}
        </Form>
    )
}
