import React from "react";
import {LoaderOverlay} from "./Loader";

export const ErrorPage = ({show}: { show: boolean }) => show ? <div>
    <div>
        <LoaderOverlay show={true}/>

        <div className="m-4" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div className="card" style={{zIndex: 1, width: "auto"}}>
                <div className="card-body">
                    Error
                </div>
            </div>
        </div>
    </div>
</div> : null;