import React from "react";
import {Search as SearchIcon, X} from 'react-feather';

interface SearchProps {
    onSearch: (searchTerm: string) => void;
    searchTerm: string;
}

export const Search = ({onSearch, searchTerm}: SearchProps) => {
    let clear;
    // TODO put styling in css - more padding for input? - size of icons should perhaps be calculated works with default height of bootstrap input only
    if (searchTerm) {
        clear = <span style={{padding: '0.375rem', position: 'absolute', right: 0}} onClick={() => {
            onSearch('');
        }}><X/></span>
    }

    const input = <input
        ref={(input) => {
            input = input
        }}
        type="text"
        className="form-control"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => {
            e.preventDefault();
            onSearch(e.target.value);
        }}
        onKeyDown={(e) => {
            console.log(e.key, e.keyCode);

            if (e.key === 'Escape' || e.keyCode === 27) {
                console.log('Escape');
                onSearch('');
            }
        }}
    />;


    return (
        <div className="search-wrap">
            <span style={{padding: '0.375rem', position: 'absolute'}}><SearchIcon/></span>
            {input}
            {clear}
        </div>
    );
}
