import React from 'react';

interface ItemSearchResultsProps {
    search: string;
    isBarcode?: boolean;
}

const ItemSearchResults = ({search, isBarcode}: ItemSearchResultsProps) => {
    if (!search) {
        return null;
    }

    let content, searchDisplay = <em>{search}</em>;
    if (isBarcode) {
        content = <div>
            Scanned Barcode: {searchDisplay}
        </div>
    } else {
        content = <div>
            Search for: {searchDisplay}
        </div>
    }

    return (
        content
    );

}

export default ItemSearchResults;
