import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {ItemOrderSelector} from "./ItemOrderSelector";
import {Item} from "../../api/drystockAPI";
import {drystockApi} from "../../services/drystock";
import {ItemOrderReceive} from "./ItemOrderReceive";
import {ModalBodyLoader} from "../Loader";

interface ItemReceiveProps {
    closeModal: () => void;
    show: boolean;
    itemName: string;
    item: Item;
    onReceived: () => {};
}

export const ItemReceive = ({closeModal, onReceived, itemName, item, show}: ItemReceiveProps) => {
    const [itemOrderId, selectItemOrderId] = useState<number | null>(null);


    const {data, error, isLoading, refetch}
        = drystockApi.endpoints.getOutstandingItemOrders.useQuery({itemId: '' + item.itemid})


    let content = null;
    if (isLoading) {
        content = <ModalBodyLoader/>
    } else if (error || !data) {
        content = <div>error</div>
    } else {
        if (itemOrderId) {
            const itemOrder = data.find(({itemorderid}) => itemorderid === itemOrderId);

            if (!itemOrder) {
                return null;
            }
            content = <ItemOrderReceive closeOnFinish onClose={() => {
                onReceived();
                refetch();
                // TODO only on receive not on close
                closeModal();
                selectItemOrderId(null);
            }} itemOrder={itemOrder}/>
        } else {
            content =
                <React.Fragment>

                    <Modal.Header closeButton>
                        <Modal.Title>{itemName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ItemOrderSelector onSelect={({itemorderid}) => {
                            selectItemOrderId(itemorderid)
                            //    TODO outstanding orders should be loaded here
                        }} itemOrders={data}/>

                    </Modal.Body>
                </React.Fragment>


        }
    }

    return (
        <Modal
            backdrop='static'
            show={show}
            onHide={closeModal}
        >

            {content}
        </Modal>
    );
};
