import React, {useEffect, useState} from 'react';
import {Tank} from "../../order-wetstock/OrderWetstock";
import {WetstockForm} from "../../order-wetstock/WetstockFormExistingOrder";
import {ModalBody} from "react-bootstrap";
import {ItemOrder} from "../../orders/OrderForm";
import {usePostDropMutation} from "../../services/drystock";

interface TSFProps {
    onClose: () => void
    itemOrder: ItemOrder
    gradeName: string
    formId: string
    closeOnFinish?: boolean
}

export const TankDropSelectModalContent = ({ closeOnFinish, itemOrder, onClose, gradeName, formId}: TSFProps) => {
    const [postDrop, postDropStatus] = usePostDropMutation({
        fixedCacheKey: `tank-drop-${itemOrder.itemorderid}`
    });

    useEffect(() => {
        if (postDropStatus.fulfilledTimeStamp !== undefined) {
            postDropStatus.reset();
            closeOnFinish && onClose();
        }
    }, [onClose, postDropStatus, closeOnFinish]);




        return (

            <WetstockForm asModalContent onCancel={()=>onClose()}  submitting={postDropStatus.isLoading} onSubmit={(dropData: any) => {
                postDrop({dropData, itemOrder: itemOrder});
            }} initialValues={{
                volume: itemOrder.unsuppliedQuantity,
                unitCost: itemOrder.unitcost
            }} id={'wetstock-order'} tanks={itemOrder.tanks}/>
        );
}
