import React from 'react';
import '../scss/tank.scss';

interface TankDisplayProps {
    water: number;
    volume: number;
    capacity: number;
    height: number;
    width: number;
}

export const TankDisplay = ({volume, capacity, water, height, width}: TankDisplayProps) => {
    const waterHeight = height * (water / capacity);
    const volumeHeight = height * (volume / capacity);

    return (
        <svg className="chart shadow-sm tank-display" aria-labelledby="title desc" role="img" width={width}
             height={height}>
            <title id="title">
                Chart showing tank status
            </title>
            <desc id="desc">Tank Graph</desc>
            <g className="capacity">
                <rect width={width} height={height}/>
                <text x="45" y="9.5" dy=".35em">Tank Capacity</text>
            </g>

            <defs>
                <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" className="grad-start"/>

                    <stop offset="50%" className="grad-end"/>

                    <stop offset="100%" className="grad-start"/>
                </linearGradient>
            </defs>


            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" className="grad-water-start"/>

                    <stop offset="50%" className="grad-water-end"/>

                    <stop offset="100%" className="grad-water-start"/>
                </linearGradient>
            </defs>

            <g className="water">
                <rect style={{fill: "url(#grad1)"}} width={width} y={height - waterHeight} height={waterHeight}/>
            </g>

            <g className="volume">
                <rect style={{fill: "url(#grad3)"}} width={width} y={height - (volumeHeight + waterHeight)}
                      height={volumeHeight}/>
            </g>
        </svg>
    );
};
