import React from 'react';
import {ReceiveStockForm} from "./ReceiveStockForm";
import {Modal} from "react-bootstrap";
import {TankDropSelectModalContent} from "./TankDropSelectModalContent";
import {ItemOrder} from "../../orders/OrderForm";


export interface ItemOrderReceiveProps {
    closeOnFinish?: boolean
    onClose: () => void
    itemOrder: ItemOrder

}


export const ItemOrderReceive = ({onClose, closeOnFinish, itemOrder}: ItemOrderReceiveProps) => {
    const {tanks} = itemOrder;


    let formId = "wetstock-form";


    let content = null;

    if (tanks && tanks.length > 0) {
        content =
            <TankDropSelectModalContent
                closeOnFinish={closeOnFinish}
                gradeName={itemOrder.itemName}
                itemOrder={itemOrder}
                onClose={() => {
                    onClose();
                }}
                formId={formId}/>
    } else {
        content =
            <Modal.Body>

                <ReceiveStockForm
                    closeOnFinish={closeOnFinish}
                    itemOrder={itemOrder}
                    onClose={onClose}
                />
            </Modal.Body>
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>{itemOrder.itemName}</Modal.Title>
            </Modal.Header>
            {content}
        </React.Fragment>
    )
};
