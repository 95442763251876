import {Field, Form} from "react-final-form";
import {AutoSave} from "../common/FinalFormUtils";
import {PurchaseOrder} from "./OrderForm";
import React from "react";

export interface TIFProps {
    supplierOrder: Partial<PurchaseOrder>
    onSubmit: (formData: PurchaseOrder) => void
    submitting: boolean
}

export const TaxInclusiveForm = ({supplierOrder, onSubmit, submitting}: TIFProps) => {
    return (
        <Form
            initialValues={supplierOrder}
            onSubmit={onSubmit}
            // subscription={{submitting: true, pristine: true, values: true}}
        >
            {({handleSubmit, submitFailed, values,  active}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <AutoSave
                            delay={0}
                            values={values}
                            submitting={submitting}
                            submit={handleSubmit}
                        />

                        <div className="form-group">
                            <div>
                                <label>Amounts are</label>
                                <div>
                                    <Field

                                        className="form-control"
                                        name="invoicetaxtype"
                                        component="select"
                                    >
                                        <option value="TAXDISPLAY_INCLUSIVE">Tax Inclusive</option>
                                        <option value="TAXDISPLAY_EXCLUSIVE">Tax Exclusive</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                        {/*</div>*/}
                    </form>
                )
            }}
        </Form>
    )
}
