import {Trash} from "react-feather";
import {Field, Form} from "react-final-form";
import {ItemOrder, PurchaseOrder} from "./OrderForm";
import React from "react";
import {useDeleteItemOrderMutation, useUpdateItemOrderMutation} from "../services/drystock";
import {AutoSave} from "../common/FinalFormUtils";
import {LoaderOverlay} from "../common/Loader";

interface IORProps {
    itemOrder: ItemOrder;
    onReceive: (itemOrderId: number) => void
    order: PurchaseOrder
}

const ReadOnlyInput = ({value, right}: { value: any; right?: boolean }) => {
    return <input type="text" readOnly className={`form-control-plaintext${right ? " text-right" : ""}`} value={value}/>
}

const generateFieldId = (itemOrderId: number, fieldName: string) => (
    `item-order-${itemOrderId}-${fieldName}`
);

export const ItemOrderRow = ({itemOrder, order, onReceive}: IORProps) => {
    let itemOrderId = itemOrder.itemorderid;

    const [updateItemOrder, updateItemOrderStatus]
        = useUpdateItemOrderMutation();

    const [deleteItemOrder, deleteItemOrderStatus]
        = useDeleteItemOrderMutation();

    const {editable, canSupply} = order;

    return (
        <Form onSubmit={(formData) => {
            updateItemOrder({id: itemOrder.itemorderid, data: formData});
        }} initialValues={{
            orderqty: itemOrder.orderqty,
            unitcost: itemOrder.unitcost,
            taxRateId: itemOrder.taxRateId
        }}>

            {
                ({handleSubmit, values, errors, active, dirty}) => {
                    return (
                        <div className="card mb-3">
                            <LoaderOverlay show={updateItemOrderStatus.isLoading}/>
                            <div className={"card-body"}>
                                {/*<div className={dirty ?  "card-body bg-warning" : "card-body"}>*/}


                                <div className="d-flex flex-wrap flex-column">
                                    <div className="mb-1">
                                        <h4>
                                            {itemOrder.itemName}
                                        </h4>


                                    </div>

                                    <div className="d-flex flex-row flex-wrap">

                                        <div className="d-flex flex-column flex-wrap">
                                            <div className="flex-wrap d-flex flex-row">
                                                <form onSubmit={handleSubmit}>
                                                    <AutoSave
                                                        delay={1500}
                                                        values={values}
                                                        submitting={updateItemOrderStatus.isLoading}
                                                        submit={handleSubmit}
                                                    />
                                                    <div className="mr-1">


                                                        <div className="form-group">
                                                            <label
                                                                htmlFor={generateFieldId(itemOrderId, 'qty')}>{itemOrder.quantityText}</label>
                                                            {editable ?
                                                                <Field
                                                                    component="input"
                                                                    id={generateFieldId(itemOrderId, 'qty')}
                                                                    name='orderqty'
                                                                    type='number'
                                                                    step="0.0001"
                                                                    className="form-control"
                                                                />

                                                                :
                                                                <ReadOnlyInput value={itemOrder.orderqty}/>

                                                            }
                                                        </div>

                                                    </div>

                                                </form>
                                                <div className="mr-1">


                                                    <div className="form-group">
                                                        <label
                                                            htmlFor={generateFieldId(itemOrderId, 'unitcost')}>
                                                            {itemOrder.unitCostText}
                                                        </label>
                                                        {editable ?

                                                            <Field
                                                                component="input"
                                                                id={generateFieldId(itemOrderId, 'unitcost')}
                                                                name='unitcost'
                                                                type='number'
                                                                step="0.0001"
                                                                className="form-control"
                                                            />
                                                            :
                                                            <ReadOnlyInput value={itemOrder.unitcost}/>
                                                        }
                                                    </div>


                                                </div>

                                                <div className="mr-1">

                                                    <div className="form-group">
                                                        <label
                                                            htmlFor={generateFieldId(itemOrderId, 'tax-rate')}>
                                                            {itemOrder.taxRateText}
                                                        </label>
                                                        {editable ?

                                                            <Field
                                                                id={generateFieldId(itemOrderId, 'tax-rate')}
                                                                className="form-control"
                                                                name="taxRateId"
                                                                component="select"
                                                            >
                                                                {
                                                                    order.validTaxRates.map(({
                                                                                                 description,
                                                                                                 taxrateid
                                                                                             }) => {
                                                                        return (
                                                                            <option
                                                                                value={taxrateid}>{description}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Field>
                                                            : <ReadOnlyInput value={itemOrder.taxDescription}/>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="mr-1">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor={generateFieldId(itemOrderId, 'total')}>{itemOrder.totalText}</label>
                                                        <ReadOnlyInput value={itemOrder.total}/>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="flex-wrap d-flex flex-row">
                                                {!editable ?

                                                    <React.Fragment>
                                                        <div className="form-group mr-1">
                                                            <label
                                                                htmlFor={generateFieldId(itemOrderId, 'supplied-quantity')}>
                                                                {itemOrder.suppliedQuantityText}
                                                            </label>
                                                            <ReadOnlyInput value={itemOrder.suppliedQuantity}/>
                                                        </div>
                                                        {itemOrder.supplyCosts.length > 0 ?
                                                            <div className="form-group mr-1">
                                                                <label
                                                                    htmlFor={generateFieldId(itemOrderId, 'supplied-quantity')}
                                                                >Supplied Cost</label>
                                                                <ReadOnlyInput
                                                                    value={itemOrder.supplyCosts[0].cost}/>
                                                            </div>
                                                            : null}
                                                    </React.Fragment> : null}

                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center ml-auto">
                                            {/*allows negative unsupplied*/}
                                            {canSupply && itemOrder.unsuppliedQuantity !== 0 ?
                                                <button className="btn btn-primary"
                                                        onClick={() => onReceive(itemOrderId)}>{order.receiveText.one}
                                                </button> : null}

                                            {editable ? <button
                                                className="btn btn-danger ml-1"
                                                title="Delete"
                                                disabled={deleteItemOrderStatus.isLoading}
                                                onClick={() => deleteItemOrder(itemOrder)}
                                            >
                                                <Trash/>
                                            </button> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        </Form>
    );
};
