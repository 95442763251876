export default function getCsrfToken() {
    const metaTags = document.getElementsByTagName('meta');
    let csrfToken;

    // get CSRF token from meta tag
    for (let i = 0; i < metaTags.length; i++) {
        let metaTag = metaTags[i];
        if (metaTag.name === 'csrf-token') {
            csrfToken = metaTag.content;
        }
    }
    return csrfToken;
}
