import React from 'react';
import ReactDOM from 'react-dom/client';
import {ReceiveStock} from "./ReceiveStock";
import {Provider} from "react-redux";
import store from "../app/store";

export default () => {
    const container = document.getElementById('receive-stock');

    if (container) {
        const siteId = container.getAttribute('data-site-id');

        const root = ReactDOM.createRoot(
            container
        )

        if (siteId) {
            root.render(
                <Provider store={store}>
                    <ReceiveStock siteId={+siteId}/>
                </Provider>
            )
        }
    }
};
