import {Action, configureStore} from '@reduxjs/toolkit'
import {ThunkAction} from 'redux-thunk'
import rootReducer from './rootReducer'
import {setupListeners} from "@reduxjs/toolkit/query";
import {drystockApi} from "../services/drystock";
import {reconciliationApi} from "../services/reconciliation";
import {itemApi} from "../services/item";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware().concat(
            drystockApi.middleware,
            reconciliationApi.middleware,
            itemApi.middleware,
        )
    )
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action>

export type RootState = ReturnType<typeof store.getState>

export default store;
