import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import {renderField} from "./StocktakeForm";
import {StockLocation} from "../../api/stocktakeAPI";
import {
    useGetStockLocationDataQuery,
    usePostAdjustmentLocationMutation,
    usePostFinaliseLocationStocktakeMutation
} from "../../services/drystock";

interface LocationBasedStocktakeFormProps {
    onCancel: () => void;
    onFinalise: () => void;
    locations: StockLocation[];
    itemId: number
}

export const LocationBasedStocktakeForm = ({
                                               itemId,
                                               onCancel,
                                               locations,
                                               onFinalise
                                           }: LocationBasedStocktakeFormProps) => {

    const [postAdjustmentLocation, postAdjustmentLocationStatus] = usePostAdjustmentLocationMutation();
    const [postFinaliseLocationStocktake, postFinaliseLocationStocktakeStatus] = usePostFinaliseLocationStocktakeMutation();

    useEffect(() => {
        if (postFinaliseLocationStocktakeStatus.fulfilledTimeStamp !== undefined) {
            onCancel();
        }
    }, [onCancel, postFinaliseLocationStocktakeStatus]);

    const {
        data: locationData,
        error,
        isLoading,
        isError,
        isFetching
    } = useGetStockLocationDataQuery({id: itemId});

    if (isLoading) {
        return null;
        //     TODO show loader
    }

    if (!locationData || isError) {
        // TODO display error ?
        return null;
    }

    // TODO error handling
    // TODO finalise move to drystockAPI


    return (
        <div>
            <p>
                Stock quantities for locations.
            </p>
            <div>
                <p>Enter stocktake quantities for the following locations:</p>

                {locations.map(({stocklocationid, description}) => {

                    const dataForLocation = locationData.data.find((datum) => datum.id == stocklocationid);


                    return (
                        <Form

                            initialValues={{quantity: dataForLocation ? dataForLocation.quantity : 0}}

                            // mutators={{...arrayMutators}}
                            // initialValues={answers}


                            onSubmit={async (formData: { quantity: number }, form) => {
                                postAdjustmentLocation({
                                    itemId,
                                    locationId: stocklocationid,
                                    data: formData
                                })
                            }}

                            subscription={{submitting: true, pristine: true, values: true}}
                        >

                            {({handleSubmit, pristine, submitting, submitFailed, values, active}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        {/*<AutoSave*/}

                                        {/*    values={values}*/}
                                        {/*    submitting={false}*/}
                                        {/*    submit={handleSubmit}*/}
                                        {/*    delay={500}*/}
                                        {/*/>*/}

                                        <Field
                                            label={description}
                                            type={"number"}
                                            component={renderField}
                                            className={"form-control"}
                                            name={`quantity`}
                                            required
                                        />


                                        <div className="clearfix">
                                            <div className="btn-group pull-right">
                                                <button
                                                    disabled={postAdjustmentLocationStatus.isLoading || submitting || pristine}
                                                    type="submit"
                                                    className="btn btn-primary">Save
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        </Form>
                    )
                })}
            </div>

            {/* TODO modal footer */}
            <div className="mt-1">
                <button type="button" className="btn btn-secondary" onClick={onCancel}>Close
                </button>

                <button
                    disabled={!locationData.complete || isFetching || postAdjustmentLocationStatus.isLoading  || postFinaliseLocationStocktakeStatus.isLoading}
                    type="button"
                    className="btn btn-primary float-right"
                    onClick={() => {
                        // TODO separate state for finalisation / error handling
                        postFinaliseLocationStocktake({itemId});
                    }}>Update
                </button>
            </div>
        </div>
    )
}
