import React, {useState} from 'react';
import barcodeScanning from "../helpers/barcodeScanning";
import {Search} from "./Search";


interface ItemSearchProps {
    onSearch: (searchChange: ItemSearchChange) => void;
    searchTimeout?: number;
}

export interface ItemSearchChange {
    isBarcode: boolean;
    searchTerm: string
}

export const ItemSearch = ({searchTimeout, onSearch}: ItemSearchProps) => {
    const [searchTerm, setSearchTerm] = useState('');

    //  TODO typing for barcode scanning js
    // @ts-ignore
    barcodeScanning((barcode) => {
        setSearchTerm('');
        onSearch({
            searchTerm: barcode,
            isBarcode: true
        });
    });

    // TODO search / adjustment error handling

    return (
        <Search
            onSearch={(q: string) => {
                // TODO pull some of this out into state handlers

                setSearchTerm(q);

                if (searchTimeout) {
                    clearTimeout(searchTimeout);
                }

                setTimeout(() => {
                    onSearch({
                        searchTerm: q,
                        isBarcode: false
                    });
                }, searchTimeout ? searchTimeout : 150)
            }} searchTerm={searchTerm}
        />
    );
}
